// Import React
import React, { useContext, useState, useEffect,  useRef } from 'react';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import { VscOpenPreview } from "react-icons/vsc";
import { DataContext } from './headerLayout';
import '../App.css';
import './publishStudio.css';
import Integrations from './integrations';

import { useLocation,useNavigate } from 'react-router-dom';


const PublishStudio = (props) => { 
    console.log(props);  // added props here


    const { currentPage, setCurrentPage } = useContext(DataContext);
    const { selectedNav, setSelectedNav} = useContext(DataContext);
    const { conversationId, setConversationId } = useContext(DataContext);
    const { templateTitle, setTemplateTitle } = useContext(DataContext);
    const { mergedContent, setMergedContent } = useContext(DataContext);

    console.log(`Conversation ID: ${conversationId}`);

    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [templateText, setTemplateText] = useState('');  // New state variable for the template text
    const [editorHasText, setEditorHasText] = useState(false);
    const [text, setText] = useState(templateText); 
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();

    const onEditorStateChange = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const hasText = contentState.hasText();
      
        setEditorHasText(hasText);
        setEditorState(editorState);
    };        

    const onEditClick = () => {
        //templateText

        const test = templateText.toString()
        const contentState = ContentState.createFromText(test);
        const editorState = EditorState.createWithContent(contentState);
        
        setEditorState(editorState);
        setEditorHasText(true);    

    }

    const [selectedOption, setSelectedOption] = useState();  // Set initial state to 'Email'
    const [secondOptions, setSecondOptions] = useState([]);
   
  
    useEffect(() => {
        const fetchConversationDetails = async () => {
            if (conversationId) {
                const formData = new FormData();
                formData.append("conversation_id", conversationId);

                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadLastConversationResponse`, {
                    //const response = await fetch(`/chatOutputs/loadLastConversationResponse`, {
                            method: "POST",
                        body: formData,
                        credentials: 'include'
                    });
                    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const responseData = await response.json();
                    console.log(typeof responseData);
                    console.log(responseData);
                    //setConversationText(responseData);           

                    const htmlResponseData = `<p>${responseData}</p>`;
                    const blocksFromHTML = htmlToDraft(htmlResponseData);
                    const { contentBlocks, entityMap } = blocksFromHTML;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const newEditorState = EditorState.createWithContent(contentState);
                
                    setEditorState(newEditorState);    
                    setEditorHasText(true);                
                    
                } catch (error) {
                    console.error('Failed to fetch preview conversation detail:', error);
                }
            }
        };
        fetchConversationDetails(); // call the async function

    }, [conversationId, selectedOption]);

    const handlePreviewClick = async () => {
        // Convert the EditorState to raw text
        setLoading(true);

        const contentState = editorState.getCurrentContent();
        const editorText = stateToHTML(contentState);
    
        // Now, send the data to your webservice
        const formData = new FormData();
        formData.append("template_text", templateText);
        formData.append("editor_text", editorText);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/previewAPI/mergeContent`, {
            //const response = await fetch(`/previewAPI/mergeContent`, {
                    method: "POST",
                body: formData,
                credentials: 'include'
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseText = await response.text();
    
            setMergedContent(responseText);
    
        } catch (error) {
            console.error('Failed to merge content:', error);
        }
        setLoading(false);
    };

    const handleIntegrationsClick = () => {
        setCurrentPage('Integrations');
        setSelectedNav('/integrations');
        navigate('/headerLayout/integrations')
      }    

    useEffect(() => {
        const fetchTemplateText = async () => {
    
            if (templateTitle) {  // Fetch only if templateTitle is set
                    
                const formData = new FormData();
                formData.append("category", selectedOption);  // Update here with the category if necessary
                formData.append("template_title", templateTitle);  // Use the selectedOption as the template_title
            
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/previewAPI/getContentTemplateText`, {
                    //const response = await fetch(`/previewAPI/getContentTemplateText`, {
                        method: "POST",
                    body: formData,
                    credentials: 'include'
                    });
            
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
            
                    const templateContent = await response.json();
                    setTemplateText(templateContent);  // Update the template text state
            
                } catch (error) {
                    console.error('Failed to fetch template text:', error);
                }
            }
        };
        
        if (templateTitle) {
            fetchTemplateText();  // Call the async function whenever templateTitle changes
        }
    
    }, [templateTitle, selectedOption]);
    
      const handleTemplateTitleChange = (e) => {
        setTemplateTitle(e.target.value);
      };

      const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    
        let options;
        switch (e.target.value) {
        case 'Email':
            options = ["Welcome Email"];
            break;
        case 'Blog':
            options = ["Short Blog"];
            break;
        case 'HTML':
            options = ["Firecrest HTML Styling"];
            break;            
        default:
            options = [""];
            break;
        }
        setSecondOptions(options);
        setTemplateTitle(options[0]);  // Update the template title to the first option
    };
        
    return (
        <section id="previewSection" className='previewSection'>
        <div className="previewContainer" id="preview" role="tabpanel" aria-labelledby="chatbot-tab">
            <div className="previewDiv">
            <label htmlFor="contentTemplate" className="label-left">Content Templates</label>
                <div style={{display: 'flex'}}>
                    <select 
                        id="contentTemplate" 
                        className="form-select large-dropdown" 
                        size="4" 
                        onChange={handleSelectChange}
                        value={selectedOption} // This is the value managed by React's state
                        >
                        <option value="Email">Email</option>
                        <option value="Blog">Blog</option>
                        <option value="HTML">HTML</option>
                    </select>

                    <select 
                        id="templateTitle" 
                        className="form-select large-dropdown" 
                        size="4"
                        onChange={handleTemplateTitleChange}  // Add an onChange event handler
                        value={templateTitle}  // Control value with React's state
                        >
                        {secondOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                        </select>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center'}}>                    
                    <textarea 
                        rows={6} 
                        id="message-input"
                        className="search-container"
                        style={{ width: '86.35vw', marginTop: '5px', marginRight: '0px', marginBottom: '0px' }}
                        value={templateText}
                        onChange={event => setTemplateText(event.target.value)}
                    />
                    </div>
                </div>
        </div>
        <div className="previewEditor" id="previewEditor" role="tabpanel" aria-labelledby="chatbot-tab">
        <label htmlFor="mergeText" className="label-left">Additional Content</label>
            <div className="editor">
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', cursor: loading ? 'wait' : 'default' }} >
                <button 
                type="button"
                className="chat-btn" 
                style={{ marginTop: '0px', marginLeft: '5px', marginRight: '5px' }}
                onClick={handlePreviewClick}
                > Generate    
                </button>
            </div>

        </div>
        <div className="generatedContainer" id="generatedContainer" role="tabpanel" aria-labelledby="chatbot-tab">

        <textarea 
            rows={15} 
            id="generated-area"
            className="search-container"
            style={{ width: '86.35vw', marginTop: '5px', marginRight: '0px', marginBottom: '0px' }}
            value={mergedContent.replace(/\\n/g, '\n')}
        />  

            <div style={{ display: 'flex', justifyContent: 'center' }}>

            <button 
                id="integrationsButton"
                type="button"
                className="chat-btn" 
                style={{ marginTop: '0px', marginLeft: '5px', marginRight: '5px' }}
                onClick={handleIntegrationsClick}
                > Integrations           
            </button>            
 
            </div>

        </div>
        </section>            
    )
}

// Export the component
export default PublishStudio;