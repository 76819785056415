import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import './dashboard.css';
import '../App.css';

function LLMButton({conversationId}) {

  const [model, setModel] = useState('');
  const [maxTokens, setMaxTokens] = useState(0);
  const [temperature, setTemperature] = useState(0);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  
  const [showModal, setShowModal] = useState(false); 

  const setShow = (isOpen) => {
    setShowModal(isOpen);
    setShow(isOpen);
  }

  useEffect(() => {

    const fetchData = async () => {
      // API call here 

         
      const formData = new FormData();
      formData.append('conversation_id', conversationId);
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadUserModel`, {
                method: "POST",
        body: formData,
        credentials: 'include' 
        });
        const { model, max_tokens, temperature } = await response.json();
  
        setModel(model);
        setMaxTokens(max_tokens);
        setTemperature(temperature);

      } catch (error) {
          console.error("Error while loading llm model.", error);
          return {
              reply: "Error occurred loading your llm model",
          }; 
      }      
    };

    if (showModal) {
      fetchData();
    }

  }, [showModal]); //Reload when modal opens

    useEffect(() => {

      const fetchUserModel = async () => {
    
        const formData = new FormData();
        formData.append('conversation_id', conversationId);
        try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadUserModel`, {
                  method: "POST",
          body: formData,
          credentials: 'include' 
          });
          const { model, max_tokens, temperature } = await response.json();
    
          setModel(model);
          setMaxTokens(max_tokens);
          setTemperature(temperature);
  
        } catch (error) {
            console.error("Error while loading llm model.", error);
            return {
                reply: "Error occurred loading your llm model",
            }; 
        }        

      };
      
      fetchUserModel();
    
    }, []);
      
  // Define a function to update maxTokens based on the selected model
  const updateMaxTokens = (selectedModel) => {
    switch (selectedModel) {
      case 'gpt-4':
        setMaxTokens(100); // Example value for GPT-4
        break;
      case 'palmapi':
        setMaxTokens(500); // Example value for Google PaLM
        break;
      // Add more cases for other models
      default:
        setMaxTokens(100);
    }
  };

  useEffect(() => {
    updateMaxTokens(model);
  }, [model]);

  //saveLLMSettings
  async function saveLLMSettings() {
      const formData = new FormData();
      formData.append("conversation_id", conversationId);  // Append conversation_id to formData
      formData.append("model", model);        
      formData.append("max_tokens", maxTokens);        
      formData.append("temperature", temperature);   
  
      try {
          const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/saveLLMSettings`, {
                  method: "POST",
          body: formData,
          credentials: 'include' 
          });
          const responseData = await response.json();
          handleClose()
          return responseData;  // Return the whole response data, not just the reply
      } catch (error) {
          console.error("Error while saving llm.", error);
          handleClose()
          return {
              reply: "Error occurred while saving llm.",
          }; 
      }
      
  }

  return (
    <>

    <Button
      className="chat-btn" variant="primary" 
      style={{ marginTop: '10%', marginBottom: '10px' }} 
      onClick={() => setShowModal(true)}>Models
    </Button>


    {showModal && (
      <Modal show={showModal}
      onHide={() => setShowModal(false)}>
      
        <Modal.Header closeButton>
          <Modal.Title>LLM Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Model:</Form.Label>
              <Form.Select 
                id="modelSelect" 
                className="form-select large-dropdown"
                value={model}
                onChange={(e) => {
                  const selectedModel = e.target.value;
                  setModel(selectedModel);
                }}
                
              >
                <option value="gpt-4">GPT4 - OpenAI's latest</option>
                <option value="gpt-35">GPT35 16k - OpenAI prior with more tokens</option>
                <option value="claude" disabled>Anthropic's Claude</option>
                <option value="palmapi">Google PaLM LangChain Text-Bison-001</option>
                <option value="corkscrewllm" disabled>FirecrestAI CorkScrewLLM</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Max tokens:</Form.Label>
              <Form.Control
                type="range"
                id="max_tokens"
                value={maxTokens}
                min="1"
                max="8192" // You can adjust this based on the selected model if needed
                step="1"
                onChange={(e) => setMaxTokens(e.target.value)}
              />
              <div>{maxTokens}</div> {/* Displaying the current value */}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Temperature:</Form.Label>
              <Form.Control 
                type="number" 
                id="temperature" 
                value={temperature} 
                min="0" max="1" 
                step="0.01"
                onChange={(e) => setTemperature(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary"  onClick={handleClose}>Close</Button>

          <Button className="chat-btn" variant="primary" onClick={() => {
            saveLLMSettings(); 
            handleClose();
          }}>
            Save
          </Button>


        </Modal.Footer>
      </Modal>
      )}

      </>
  
    );
  
  }

export default LLMButton;
