import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function ConfirmEmail() {
  let { token } = useParams();
  const navigate = useNavigate();

  const handleConfirmEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/confirm-email/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          alert(data.message);
          navigate('/');
        } else {
          alert(data.message);
          navigate('/');
        }
      } else {
        console.error('Failed to confirm email');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center" style={{height:"100vh"}}>
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h2 className="text-center mb-5">Confirm Email</h2>
              <button 
                className="custom-btn btn-primary btn-block" 
                onClick={handleConfirmEmail}
              >
                Confirm Email
              </button>
              <div className="text-center mt-3">
                <small>
                  <button className="btn btn-link" onClick={() => navigate('/login')}>Back to login</button>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmEmail;
