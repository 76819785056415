import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LoginForm from './modules/login';
import ForgotPassword from './modules/forgotPassword';
import ResetPassword from './modules/resetPassword';
import RegisterForm from './modules/register';
import ConfirmEmail from './modules/confirmEmail';

import HeaderLayout from './modules/headerLayout';
import Preview from './modules/preview';
import PublishStudio from './modules/publishStudio';
import Integrations from './modules/integrations';
import Dashboard from './modules/dashboard';

//hi
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/confirmEmail/:token" element={<ConfirmEmail />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/register" element={<RegisterForm />} />

          {/* Nested routes using the header layout */}
          <Route path="/headerLayout" element={<HeaderLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="preview" element={<Preview />} />
            <Route path="publishStudio" element={<PublishStudio />} />
            <Route path="integrations" element={<Integrations />} />
          </Route>          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
