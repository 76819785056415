import React, { useState, useEffect,  useContext, useRef } from 'react';

import './dashboard.css';
import '../App.css';
import './preview.css';
import PublishStudio from './publishStudio';
import ReactMarkdown from 'react-markdown';
import { useLocation,useNavigate } from 'react-router-dom';

import { DataContext } from './headerLayout';

const Preview = (props) => { 
    const { currentPage, setCurrentPage } = useContext(DataContext);
    const { selectedNav, setSelectedNav} = useContext(DataContext);
    const { conversationId, setConversationId } = useContext(DataContext);

    const [publishOptions, setPublishOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationText, setConversationText] = useState('');
    const navigate = useNavigate();
    
    console.log(`Conversation ID: ${conversationId}`);

    {loading ? 
        document.body.style.cursor = 'wait' :
        document.body.style.cursor = 'default'
        }

    const handleCopyClick = async () => {
        try {
          await navigator.clipboard.writeText(conversationText);
          alert('Copied to clipboard!');
        } catch(err) {
          console.error('Failed to copy text: ', err);
        }
      }
       
    const handlePublishClick = () => {
        setCurrentPage('Publish');
        setSelectedNav('/publishStudio');
        navigate('/headerLayout/publishStudio')
      }    

    useEffect(() => {
        const fetchConversationDetails = async () => {
            setLoading(true);
            if (conversationId) {
                const formData = new FormData();
                formData.append("conversation_id", conversationId); 

                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadLastConversationResponse`, {
                            method: "POST",
                        body: formData,
                        credentials: 'include'
                    });
                    
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const responseData = await response.json();
                    console.log(typeof responseData);

                    // Get 'text' property of first element
                   // const text = responseData;
                    const text = responseData[0][0];
                    //const text = responseData[0].text;

                    console.log('responseData: ${text}');
                    setConversationText(text); 

                } catch (error) {
                    console.error('Failed to fetch preview conversation detail:', error);
                }
            }
        };
        fetchConversationDetails(); // call the async function
        setLoading(false);
    }, [conversationId, conversationText]);
    
    return (
        <section id="previewSection" className='previewSection'>

        <div style={{height: '700px'}} className="previewEditor" id="previewEditor" role="tabpanel" aria-labelledby="chatbot-tab">
        <label htmlFor="mergeText" className="label-left">Preview Content</label>
            <div className="html-container " id="html-container">
            <ReactMarkdown >{conversationText}</ReactMarkdown>
            </div>


        </div>
        <div className="generatedContainer" id="generatedContainer" role="tabpanel" aria-labelledby="chatbot-tab">
 

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {props.currentPage === 'Publish' ? 
                <PublishStudio  /> 
                    : null
                }
 
            <button 
                id="publishButton"
                type="button"
                className="chat-btn" 
                style={{ marginTop: '0px', marginLeft: '5px', marginRight: '5px' }}
                onClick={handleCopyClick}
                > Copy            
            </button>

            </div>

        </div>
        </section>            
    )
}

export default Preview;

