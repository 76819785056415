import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineUser, AiOutlineLock, AiFillGoogleCircle } from 'react-icons/ai';
import './login.css';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [setLoginError] = useState('');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault(); // prevent the default form submission behavior
    setLoading(true);
  
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('rememberMe', rememberMe);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/login`, {
          method: 'POST',
        body: formData,
        credentials: 'include' 
      });
  
      if (response.ok) {
        navigate('/headerLayout');
      } else {
        const text = await response.text();
        setLoginError(text);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault(); // add this
  
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/login/google_login`; 
  };

  return (
    <div className="login-container">
      <div className="flex-container">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/assets/Firecrest-logo-v1.png`} alt="Firecrest Logo" className="logo" />
        </div>
        <div className="login-card">
          <h2 className="title text-center mb-5">Sign In</h2>
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text"><AiOutlineUser /></span>
            </div>
            <input type="text" id="email" placeholder="Email" className="form-control" required value={email} onChange={e => setEmail(e.target.value)} />
          </div>

          <form onSubmit={handleLogin}> {/* wrap the inputs and login button with a form */}
   
          <div className="input-group mb-4">
            <div className="input-group-prepend">
              <span className="input-group-text"><AiOutlineLock /></span>
            </div>
            <input type="password" id="password" placeholder="Password" 
                className="form-control" required value={password} onChange={e => setPassword(e.target.value)} />
          </div>
          
          <button type="submit" className="login-btn"
                 style={{ cursor: loading ? 'wait' : 'default' }}>Login</button>

          <div className="separator">
            <span>or</span>
          </div>
          <button className="google-btn" onClick={handleGoogleLogin}><AiFillGoogleCircle className="mr-2"/>Google</button>
          <div className="d-flex justify-content-between mt-5 bottom-text">
            <small><Link to="/ForgotPassword">Forgot password?</Link></small>
            <small><Link to="/register">Don't have an account?</Link></small>
          </div>

          </form>
        </div>
      </div>
    </div>
  );  
}

export default LoginForm;
