import React, { useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './login.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const forgotPassword = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/forgot-password`, {
      //const response = await fetch(`/forgot-password`, {
          method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'email': email
        })
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message);
      } else {
        const data = await response.json();
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="title text-center mb-5">Forgot Password</h2>
        <div className="instructions-text">
          <p>Please enter your email address<br />
          You will receive a link to create a new password</p>
        </div>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text"><AiOutlineMail /></span>
          </div>
          <input 
            type="email" 
            id="email" 
            placeholder="Email Address" 
            className="form-control"
            value={email}
            onChange={e => setEmail(e.target.value)} 
            required 
          />
        </div>
        <button 
          className="register-btn" 
          onClick={forgotPassword}
        >
          Reset Password
        </button>

        <div id="message" className="mt-3"></div>
        
        <div className="d-flex justify-content-between mt-5 bottom-text">
          <small><Link to="/">Back to login</Link></small>
          <small></small>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
