import React, { useState, useEffect,  useContext, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faUser } from '@fortawesome/free-solid-svg-icons';
import { PiChatLight } from 'react-icons/pi';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { AiOutlinePlus} from "react-icons/ai";
import { VscOpenPreview } from "react-icons/vsc";
import { BiTrash } from "react-icons/bi";
import { useLocation,useNavigate } from 'react-router-dom';

import './dashboard.css';
import Preview from './preview';
import LLMButton from './llmButton';
import { DataContext } from './headerLayout';

// import { useRouteMatch } from 'react-router-dom';

import { useDashboardServer } from './dashboardServer.js'; 
  
const Dashboard = () => {
    
    const { currentPage, setCurrentPage } = useContext(DataContext);
    const { selectedNav, setSelectedNav} = useContext(DataContext);
    const { conversationId, setConversationId } = useContext(DataContext);
    const { message, setMessage } = useContext(DataContext);
    //const [selectedConversation, setSelectedConversation] = useState(null);
    const {selectedConversation, setSelectedConversation} = useContext(DataContext);


    const formRef = useRef(null);
    // console.log(`Dashboard Conversation ID: ${conversationId}`);
    React.useEffect(() => {
        setCurrentPage('Dashboard'); 
    }, []);

    const chatEndRef = React.createRef();
    const navigate = useNavigate();

    const handlePreviewClick = () => {
        setCurrentPage('Preview');
        setSelectedNav('/preview');
        navigate('/headerLayout/preview');
     /*   setTimeout(() => {
            setConversationId(null);
          }, 1000);*/
          
      }

    const [orgId, setOrgId] = useState(null); 
    const [groupId, setGroupId] = useState(null); 
    const [prompts, setPrompts] = useState('');

    const location = useLocation();
    //const { setPage } = location.state;

   
    const { publicOrgs, privateOrgs, groups, fetchOrgData, fetchGroups,
            fetchPrompts, addMessageToChat, fetchConversations,
            fetchChatbotResponse, clearChatHistory } = useDashboardServer();
    const [orgs, setOrgs] = useState([]);
    //const [message, setMessage] = useState(''); // New state for the message form input
    const [chat, setChat] = useState([]); // New state for the chat messages
    const [showPromptDiv, setShowPromptDiv] = useState(false);

    const [selectedPrompt, setSelectedPrompt] = useState('');
    const [conversations, setConversations] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [selectedConversation, setSelectedConversation] = useContext(DataContext);
    


    const scrollToBottom = () => {
        console.log("Trying to scroll to bottom...");
        console.log("chatEndRef.current:", chatEndRef.current);
        if (chatEndRef.current != null) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
        
      };

    // Handle change event for the prompts textarea
    const handlePromptChange = (e) => {
      setSelectedPrompt(e.target.value);
    };
    
    const usePrompt = () => {
        setMessage(prompts);
    };

    const togglePromptDiv = () => {
        if (!showPromptDiv && publicOrgs.length > 0) {
          const firstOrgId = publicOrgs[0].id;
          setOrgId(firstOrgId);
          fetchGroups(firstOrgId);
        }
        setShowPromptDiv(!showPromptDiv);
      };    

    const handleOrgChange = (event) => {
        const selectedValue = event.target.value;
        setOrgId(selectedValue);
        fetchGroups(selectedValue);
    };    

    const handleGroupChange = async (e) => {
        const groupId = e.target.value;
        const fetchedPrompts = await fetchPrompts(groupId);
        const promptTexts = fetchedPrompts.map(prompt => prompt.text).join('\n');
        setPrompts(promptTexts);
    };
          
    const handleSubmit = () => {
        // Handle your form submission here
        setIsSubmitted(true);
      };


    const [isIdeaClicked, setIsIdeaClicked] = useState(false); // new state to check if idea was clicked

    useEffect(() => {
        if (isIdeaClicked) {
            handleFormSubmit();
            setIsIdeaClicked(false); // reset the flag
        }
    }, [message]);
    
    const handleIdeaClick = (event) => {
        event.preventDefault();
        const ideaText = event.target.innerText || event.target.textContent;
        setMessage(ideaText);
        setIsIdeaClicked(true); // set the flag to true after updating the message
    };

    const handleFormSubmit = async (event) => {
        if (event) 
            event.preventDefault();

        if (message === '') {
          alert('Please enter a message.');
          return;
        }
    
        // document.body.style.cursor = 'wait';
    
        const requestData = {
            message: message,
            conversation_id: conversationId, 
        };
        
        setChat([...chat, { sender: 'User', message }]);
        // scrollToBottom();
        
        setMessage(''); // Clear the message field immediately after sending the message
    
        setIsProcessing(true); // Set processing state to true before making the request
        setIsSubmitted(true);

        const response = await fetchChatbotResponse(requestData);

        setIsProcessing(false); // Set processing state back to false after receiving the response

        const { reply, conversation_id } = response; 
    
        setChat([...chat, { sender: 'User', message }, { sender: 'AI', message: reply }]);
        setConversationId(conversation_id);
        // scrollToBottom();
        const updatedConversations = await fetchConversations();
        setConversations(updatedConversations);
        if (conversationId === null || conversationId === 0) {
            setSelectedConversation(conversation_id);
        }
        

        document.body.style.cursor = 'default';
    };

    const deleteChat = async (id) => {
        console.log(`Deleting chat with ID: ${id}`);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/deleteConversation/${id}`, {
            //const response = await fetch(`/chatOutputs/deleteConversation/${id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const updatedConversations = conversations.filter(conversation => conversation.id !== id);
            setConversations(updatedConversations);
            handleClearChat(); // Call handleClearChat function here
        } catch (error) {
            console.error('Failed to delete conversation:', error);
        }
    };

    const loadCurrentConversation = async (event) => {
        if (event != null) {
            event.preventDefault();
        }
        // if we are reloading
        if (conversationId > 0) {

            setLoading(true);

            setIsSubmitted(true);
            if (selectedConversation === null) {
                setSelectedConversation(conversationId);
            }
            
            
            const formData = new FormData();
            formData.append("conversation_id", conversationId);
        
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadConversationDetails`, {
                    method: "POST",
                    body: formData,
                    credentials: 'include'
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const interwovenChatHistory = await response.json();
        
                // Make pairs of messages (user-bot).
                let messagePairs = [];
                for (let i = 0; i < interwovenChatHistory.length; i += 2) {
                    messagePairs.push(interwovenChatHistory.slice(i, i + 2));
                }
        
                // Reverse the pairs (not the order within the pairs).
                messagePairs = messagePairs.reverse();
        
                // Now we flatten the array again.
                const reversedChat = [].concat(...messagePairs);
        
                // Update your chat state with the fetched and rearranged chat history.
                setChat(reversedChat);
        
                setConversationId(conversationId)
        
            } catch (error) {
                console.error('Failed to fetch conversation detail:', error);
            }
            setLoading(false);
            
        }
    };
        
    const handleConversationSelectionChange = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        console.log("handleConversationSelectionChange triggered", event.target.getAttribute('data-id'));

        setLoading(true);

        const selectedConversationId = Number(event.target.getAttribute('data-id'));  
        setSelectedConversation(selectedConversationId);
        setIsSubmitted(true);

        // Check if the selected conversation exists before trying to fetch its details
        const selectedConversationExists = conversations.some(conversation => conversation.id === selectedConversationId);

        if (!selectedConversationExists) {
            return;  // Exit the function if the selected conversation no longer exists
        }

        const conversationId = event.target.value;
      
        const formData = new FormData();
        formData.append("conversation_id", conversationId);
      
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/loadConversationDetails`, {
                method: "POST",
                body: formData,
                credentials: 'include'
            });
      
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
      
            const interwovenChatHistory = await response.json();
    
            // Make pairs of messages (user-bot).
            let messagePairs = [];
            for (let i = 0; i < interwovenChatHistory.length; i += 2) {
                messagePairs.push(interwovenChatHistory.slice(i, i + 2));
            }
    
            // Reverse the pairs (not the order within the pairs).
            messagePairs = messagePairs.reverse();
    
            // Now we flatten the array again.
            const reversedChat = [].concat(...messagePairs);
    
            // Update your chat state with the fetched and rearranged chat history.
            setChat(reversedChat);
    
            setConversationId(conversationId)
      
        } catch (error) {
            console.error('Failed to fetch conversation detail:', error);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        if (loading) {
            document.body.style.cursor = 'wait';
        } else {
            document.body.style.cursor = 'default';
        }
    }, [loading]);
    
    const handleClearChat = () => {
        setChat([]);
        setMessage("");
        clearChatHistory();
        setConversationId(null)
        setSelectedConversation(null);
        setIsSubmitted(false);
      };
      
    const isShiftKeyPressed = (event) => {
        return event.shiftKey === true;
      };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (isShiftKeyPressed(event)) {
                // If shift key is pressed, add a new line.
                setMessage(prevMessage => prevMessage + '\n');
            } else {
                // Otherwise, submit the form.
                handleFormSubmit(event); // This will send the message and clear the form
            }
        }
    };

    const handleChange = (event) => {
        setMessage(event.target.value);
      };    

    useEffect(() => {
        fetchOrgData('public').then(data => {
          setOrgs(data);
        }
        );
    }, []);    

    useEffect(() => {
        const loadConversations = async () => {
            const convs = await fetchConversations();
            setConversations(convs);
        };

        loadConversations();
        loadCurrentConversation();
    }, [conversationId]);

    useEffect(() => {
        scrollToBottom();
    }, [chat]);

    useEffect(() => {
        console.log('Selected Conversation Changed:', selectedConversation);
    }, [selectedConversation]);
      
    useEffect(() => {
        console.log('Conversation ID Changed:', conversationId);
    }, [conversationId]);
      

    return (
        <section id="chat" style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '85vw' }}>
          <div className="container" id="chatbot" role="tabpanel" aria-labelledby="chatbot-tab">
   
            <div style={{ display: 'flex', height: '82%' }}>
      
            <div className="chat-list-container" 
                style={{ 
                    height: '100%', 
                    width: '100%', 
                    overflowY: 'auto', 
                    marginRight: '1%', 
                    display: 'flex', 
                    flexDirection: 'column',
                }}
            >
                {/* Chat messages */}
                {chat.map(({ sender, message }, index) => (
                    <div key={index} className={`${sender === 'User' ? 'user-message bg-white' : 'ai-message'} d-flex align-items-start`}>
                        <div className={`${sender.toLowerCase()}-message-icon mr-2 mt-1`}>
                            {sender === 'User' ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faRobot} />}
                        </div>
                        <div className={`${sender.toLowerCase()}-message-text`} 
                            dangerouslySetInnerHTML={{ __html: message ? message.replace(/\n/g, '<br/>') : ''}}
                        >
                        </div>
                    </div>
                ))}

                {isProcessing && (
                    <div className="ai-message d-flex align-items-start">
                        <div className="ai-message-icon mr-2 mt-1">
                            <FontAwesomeIcon icon={faRobot} />
                        </div>
                        <div className="ai-message-text">
                            <div className="loading-dots">
                                <div></div><div></div><div></div>
                            </div>
                        </div>
                    </div>
                )}

                {/* Welcome message */}
                {!isSubmitted && (
                    <div className="welcome-container">
                        <h3>Welcome to Firecrest AI</h3>
                        <p>Get started by writing a task and FirecrestAI can do the rest.<br/>
                        Not sure where to start? Click Ideas for inspiration.</p>
                        <div className="prompt-grid">
                        <div className="prompt-button" onClick={handleIdeaClick}>
                            Write a short summary on how Alternative Wine Containers are Shaping the Industry. Should discuss increasing demand, the adoption of cans, boxes, and tetra packs for wine storage is transforming the way we consume our favorite vino. Format with a separate list ideas.
                        </div>
                        <div className="prompt-button" onClick={handleIdeaClick}>
                            Create a news article on low and non-alcoholic wines gaining popularity. Break into separate paragraphs by ideas.
                        </div>
                        <div className="prompt-button" onClick={handleIdeaClick}>
                            You are a guide for St. Helena, Napa, Ca. Give detail descriptions of St. Helena and surrounding wineries and hotels.
                        </div>
                        <div className="prompt-button" onClick={handleIdeaClick}>
                            Tell me about the top wineries in the Napa area.
                        </div>
                        </div>
                    </div>
                    )}



                {/* Scroll to bottom reference */}
                <div ref={chatEndRef} style={{ marginBottom: '0'}} />
            </div>




            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '-3%' }}>
                <div style={{ paddingLeft: '10%',paddingTop: '5%', display: 'flex', justifyContent: 'center',width: '100%' }}>
                    <LLMButton conversationId={(conversationId)} />
                </div>
                
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
                    <label htmlFor="pastChats" className="label-left">Past Chats</label>
                </div>

                <div id="pastChats" className="styled-container chat-list-container" style={{ height: '100%', overflowY: 'auto' }}>
                {conversations && conversations.map((conversation) => (
                    <button 
                        key={conversation.id} 
                        value={conversation.id} 
                        className={`chat-item ${conversation.id === selectedConversation ? 'selected' : ''}`} 
                        data-id={conversation.id}
                        onClick={handleConversationSelectionChange}
                    >
                        <PiChatLight style={{ marginRight: '10px' }} />
                        {conversation.conversation_summary}
                        {conversation.id === selectedConversation && <BiTrash className="trash-icon" onClick={() => deleteChat(conversation.id)} style={{cursor:'pointer', marginLeft:'10px'}}/>
}
                    </button>
                ))}

                </div>
            </div> 
            
            </div>
    
        
            <div id="message-form" 
                style={{ display: 'flex', alignItems: 'center', height: '10%'}}>
            <div className="input-group" 
                style={{ marginRight: '10px', display: 'flex', alignItems: 'center', overflow: 'hidden', marginTop: '20px'}}>
                <textarea
                        id="message-input"
                        className="search-container"
                        style={{
                            flexGrow: 1.2,
                            marginLeft: '10px',
                            maxWidth: '120%', 
                            border: 'none',
                            padding: '10px',
                            borderRadius: '10px',
                            height: '45px',
                            outline: 'none'
                        }}
                        value={message}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown} 
                        autoComplete="off"
                    />
                    <button 
                        type="submit" 
                        className="btn send-btn" 
                        onClick={handleFormSubmit}
                        style={{
                            border: 'none',
                            backgroundColor: 'transparent',
                            cursor: 'pointer',
                            padding: '10px',
                            outline: 'none',
                            fontSize: '1.5em',
                        }}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>

                <button type="button" 
                    className="icon-btn" 
                    style={{ 
                        marginTop: '0px', 
                        marginLeft: '0px', 
                        marginRight: '10px', 
                        backgroundColor: '#f2f2f2',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        color: 'gray'
                    }} 
                    onClick={handleClearChat}>
                    <AiOutlinePlus size={20} />
                </button>

                
                {setCurrentPage === 'Preview' ? 
                <Preview /> 
                    : null
                
                }
            <button 
                id="previewButton"
                type="button"
                className="icon-btn" 
                style={{ 
                    marginTop: '0px', 
                    marginLeft: '0px', 
                    marginRight: '25px',
                    backgroundColor: '#f2f2f2',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    color: 'gray'
                }}
                onClick={handlePreviewClick}
                >
                <VscOpenPreview size={20} />                
            </button>    

                <button type="button" className="chat-btn" style={{width: '250px', marginTop: '0px', marginLeft: '5px', marginRight: '0px' }} onClick={togglePromptDiv}>Ideas</button>
         </div>
            
        </div>
        
        {showPromptDiv && (
                <div className="prompt-container" style={{ display: 'flex'}} id="promptDiv" role="tabpanel" aria-labelledby="chatbot-tab">
                <div className="listboxes-container" style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                <div className="mb-3" style={{ height: '100%', width: '33%' }}>
                    <select id="publicOrgSelect" onChange={handleOrgChange}
                        className="form-select large-dropdown" size="8" value={orgId}
                        style={{ width: "200px", height: "200px", overflow: "auto", whiteSpace: "normal" }}>
                        {publicOrgs && publicOrgs.map((org) => (
                            <option key={org.id} value={org.id}>{org.name}</option>
                        ))}
                    </select>
                    </div>
    
                    <div className="mb-3" style={{ height: '100%', width: '33%' }}>
                        <select id="groupSelect" onChange={handleGroupChange}
                            className="styled-container" size="8"
                            style={{ width: "250px", height: "200px", overflow: "auto", whiteSpace: "normal" }}>
                            {groups && groups.map((group) => (
                                <option key={group.id} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                    </div>
    
                    <div id="promptContainer" className="mb-3" style={{ height: '100%', width: '33%' }}>
                        <textarea id="promptList" className="styled-container" 
                        value={prompts} onChange={handlePromptChange} 
                        readOnly style={{backgroundColor: 'white', width: '100%', height: '200px'}}>
                        </textarea>
                          
                        <button id="usePrompt" className="prompt-btn" type="submit" 
                            style={{ marginTop: '.5%', marginLeft: '65%'}} 
                            onClick={usePrompt}>Use Prompt</button>
                    </div>
                </div>            
    
            </div>
            )}
        </section>
    )
    
}
//export default Dashboard;

const MemoizedDashboard = React.memo(Dashboard);

export default MemoizedDashboard;
