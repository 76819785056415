import { useState, useEffect } from 'react';

export const useDashboardServer = () => {
    const [publicOrgs, setPublicOrgs] = useState([]);
    const [privateOrgs, setPrivateOrgs] = useState([]);
    const [groups, setGroups] = useState([]);
    const [prompts, setPrompts] = useState([]);

    // State for conversationHistory and conversation_history
    const [conversationHistory, setConversationHistory] = useState('');
    const [conversation_history, setConversation_history] = useState('');
    const [conversations, setConversations] = useState([]);
    //const [conversation_id, setConversation_id] = useState([]);

    // fetchOrgData
    const fetchOrgData = async (access) => {
        try {
            console.log(process.env.REACT_APP_SERVER_URL);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/organizations`, {
                credentials: 'include' 
            });
            const orgData = await response.json();

            if (access === 'public') {
            setPublicOrgs(orgData);
            } else if (access === 'private') {
            setPrivateOrgs(orgData);
            } else {
            throw new Error('Invalid access parameter. It should be either "public" or "private".');
            }

        } catch (error) {
            console.error('Error fetching org data:', error);
            alert("An error occurred while fetching the organization data.");
        }
    };

    // topics
    const fetchGroups = async (orgId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/fetchGroupData/${orgId}`, {
                credentials: 'include' 
            });
            const groupData = await response.json();
            setGroups(groupData);
        } catch (error) {
            console.error('Error fetching topics data:', error);
            alert("An error occurred while fetching the topics data.");
        }
    };    

    const fetchPrompts = async (groupId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/api/prompts/${groupId}`
                , {
                    credentials: 'include' 
                });
            const promptData = await response.json();
            return promptData;  // return promptData here
        } catch (error) {
            console.error('Error fetching prompt data:', error);
            alert("An error occurred while fetching the prompt data.");
        }
    }; 

    const fetchConversations = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/conversations`, {
                    method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include' 
            });
            const conversationData = await response.json();
            setConversations(conversationData);
            return conversationData;
        } catch (error) {
            console.error('Error fetching conversations:', error);
            alert("An error occurred while fetching the conversations.");
        }
    };
    
    const fetchChatbotResponse = async (requestData) => {
        // Update the conversation history
        setConversationHistory(prevHistory => prevHistory + `User: ${requestData.message}\n`);
    
        const formData = new FormData();
        formData.append("message", requestData.message);
        formData.append("conversation_history", conversationHistory);
        formData.append("conversation_id", requestData.conversation_id);  // Append conversation_id to formData
    
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chatOutputs/chatonly`, {
                    method: "POST",
            body: formData,
            credentials: 'include' 
            });
            const responseData = await response.json();
            
            // Update the conversation history with the AI's reply
            setConversationHistory(prevHistory => prevHistory + `AI: ${responseData.reply}\n`);
            //setConversation_id('${responseData.reply}');
    
            return responseData;  // Return the whole response data, not just the reply
        } catch (error) {
            console.error("Error while sending message to the chatbot.", error);
            return {
                reply: "Error occurred while processing your message.",
            }; 
        }
    };
    
      const clearChatHistory = () => {
        setConversationHistory('');
      };

    // clearChatHistory
    const clearChatHistoryx = () => {
        const chatContainer = document.querySelector("#chat-container");
        chatContainer.innerHTML = '';
        setConversationHistory('');
        setConversation_history('');
      };     

    useEffect(() => {
        fetchOrgData('public');
        fetchOrgData('private');
        
   
    }, []);

  return {
    publicOrgs,
    privateOrgs,
    fetchOrgData,
    clearChatHistory,
    fetchChatbotResponse,
    groups,
    fetchGroups,
    prompts,
    fetchPrompts,
    fetchConversations
  }
}


