import React, { useContext, useState, useEffect,  useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { PiChatLight } from 'react-icons/pi';
import { EditorState, ContentState } from 'draft-js';
import { DataContext } from './headerLayout';

import './dashboard.css';
import '../App.css';
import './integrations.css';

//function Integrations({ category, templateTitle, mergedContent }) {
const Integrations = (props) => { 
    const [publishOptions, setPublishOptions] = useState([]);

    const { currentPage, setCurrentPage } = useContext(DataContext);
    const { selectedNav, setSelectedNav} = useContext(DataContext);
    const { conversationId, setConversationId } = useContext(DataContext);

    console.log(`Conversation ID: ${conversationId}`);

    //const contentBlock = htmlToDraft();
    //const contentBlock = htmlToDraft(mergedContent);
    //const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const initialEditorState = EditorState.createEmpty();
    //.createWithContent();
    const [pubHists, setPubHists] = useState([]);
    const [selectedPubHist, setSelectedPubHist] = useState(null);

    const [editorState, setEditorState] = useState(
        () => initialEditorState
      );
  
    const onEditorStateChange = (newEditorState) => {
      //setEditorState(newEditorState);
    }; 

    const handlePubHistSelectionChange = async (event) => {
      
    };




    return (
        <section id="publisherSection" className='previewSection' style={{ display: 'flex', height: '80%' }}>
        <div className="previewContainer" id="publisher" role="tabpanel" aria-labelledby="chatbot-tab">
        <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <div style={{ marginRight: '10px', width: '13.65vw' }}>
                <label htmlFor="outputTemplate" className="label-left">Publish Options</label>
                <select 
                    id="publishType" 
                    className="form-select large-dropdown" 
                    size="4">
                    {publishOptions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
                <label htmlFor="outputTemplate" className="label-left">Publish History</label>
    
                <div id="publishHistsDiv" className="styled-container chat-list-container" style={{ height: '100%', overflowY: 'auto' }}>
                        {pubHists && pubHists.map((pubHist) => (
                            <button 
                                key={pubHist.id} 
                                value={pubHist.id} 
                                className={`chat-item ${pubHist.id === selectedPubHist? 'selected' : ''}`} 
                                data-id={pubHist.id}
                                onClick={handlePubHistSelectionChange}
                            >
                                <PiChatLight style={{ marginRight: '10px' }} />
                                {pubHist.pubHist_content}
                            </button>
                        ))}
                    </div>            
            </div>
    
            <div className="pubEditor" style={{ width: '70vw', marginTop: '5px' }}>
                <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    
                />
            </div>
        </div>
    
        <div style={{ marginTop: '10px' }}>  {/* New div for button */}
            <button 
                type="button" 
                className="chat-btn" 
                style={{ marginLeft: '5px'}}
            > Save
            </button>
            <button 
                type="button" 
                className="chat-btn" 
                style={{ marginLeft: '5px'}}
            > Push
            </button>
        </div>
    
        </div>
    
        </section>
      )
    }

export default Integrations;

