import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AiOutlineUser, AiOutlineLock, AiOutlineMail } from 'react-icons/ai';
import './login.css';

const RegisterForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== confirmPassword) {
        alert("Passwords don't match!");
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/register`, {
        //const response = await fetch(`/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'password': password,
                'email': email
            }),
        });

        if (response.ok) {
            alert('Registration successful! Please check your email to verify your account.');
            navigate('/');
        } else {
            alert('Registration failed');
        }
    } catch (error) {
        console.error('Error:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="title text-center mb-5">Register</h2>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text"><AiOutlineMail /></span>
          </div>
          <input type="email" id="email" placeholder="Email" className="form-control" required value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text"><AiOutlineLock /></span>
          </div>
          <input type="password" id="password" placeholder="Password" className="form-control" required value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div className="input-group mb-4">
          <div className="input-group-prepend">
            <span className="input-group-text"><AiOutlineLock /></span>
          </div>
          <input type="password" id="confirm_password" placeholder="Confirm Password" className="form-control" required value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
        </div>
        <button className="register-btn" onClick={handleRegister}>Register</button>
        <div className="separator">
          <span>or</span>
        </div>
        <div className="d-flex justify-content-center mt-5 bottom-text">
          <small><Link to="/">Already have an account? Login</Link></small>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
