import React, { useState, useEffect } from 'react';
import {
  useNavigate,
  // BrowserRouter as Router,
  // Routes,
  // Route,
  Outlet,
  NavLink 
} from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

import { MdSpaceDashboard, MdCloudUpload } from 'react-icons/md';
import { RiSearchEyeLine } from 'react-icons/ri';
// import { SiGoogleanalytics } from 'react-icons/si';
// import { BsHouses } from 'react-icons/bs';

export const DataContext = React.createContext();

const HeaderLayout = () => {
  
  const [currentPage, setCurrentPage] = useState('Dashboard');
  const [selectedNav, setSelectedNav] = useState('Dashboard');
  const [currentUser, setCurrentUser] = useState(null);
  // const [currentConversationId, setCurrentConversationId] = useState(null);
  const [conversationId, setConversationId] = useState(null);
 // const [selectedConversation, setSelectedConversation] = useState(null);
 const [selectedConversation, setSelectedConversation] = useState(null);

  const [message, setMessage] = useState('');
  const [conversationText, setConversationText] = useState('');
  const [templateTitle, setTemplateTitle] = useState('');
  const [mergedContent, setMergedContent] = useState('');


  const navigate = useNavigate();

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/current_user`, {
          method: "GET",
        credentials: "include"
      });
      const userData = await response.json();

      if(response.ok){
        setCurrentUser(userData.email);
      } else {
        console.error('No user is currently logged in');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/logout`, {
          method: 'POST', // or 'GET' depending on how your endpoint is setup
        credentials: 'include', // include, *same-origin, omit
      });
  
      if (response.ok) {
        // Log out successful, navigate to login page
        navigate('/');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleManageAccount = () => {
    // Redirect user to manage account page or open manage account modal
    console.log('Managing account...');
  };

  useEffect(() => {
    console.log('HeaderLayout mounted');

    return () => {
        console.log('HeaderLayout unmounted');
    };
}, []);

  const navItems = [
    { 
        icon: <MdSpaceDashboard size={32} color="#FF4500" />, 
        text: 'Home', 
        to: {
            pathname: 'dashboard'
        }
    },
    { 
      icon: <RiSearchEyeLine size={32} color="#FF4500" />, 
      text: 'Preview', 
      to: {
          pathname: 'preview'
      }
    }
  ];

  return (
    <div className="d-flex">
      <header className="header d-flex justify-content-between align-items-center">
        <div className="logo">
          <img src={process.env.PUBLIC_URL + "/assets/Firecrest-logo-v1.png"} alt="logo text" className="logo-text" />
        </div>
        <div className="user-info d-flex align-items-center">
          <Dropdown>
          <Dropdown.Toggle as="button" className="custom-btn" id="dropdown-basic">
            {currentUser ? currentUser : 'Manage Account'}
        </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleLogout}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </div>
      </header>
      <div className="sidebar d-flex flex-column justify-content-between">
        <ul className="navbar-nav flex-column">
          <li className="nav-item" style={{marginTop: '2rem'}}>

          </li>

          {navItems.map((item, index) => (
              <li className="nav-item" key={index}>
       
                  <NavLink 
                      className={`nav-link ${item.text === selectedNav ? 'active' : ''}`}
                      to={item.to}
                      onClick={() => setSelectedNav(item.text)}
                    >
                      {item.icon}
                      <span style={{marginLeft: '10px'}}>{item.text}</span>
                  </NavLink>


              </li>
          ))}
        </ul>
      </div>
   
      <main className="main-content">

      <DataContext.Provider value={
          {currentPage, 
          setCurrentPage, 
          conversationId, setConversationId,
          selectedConversation, setSelectedConversation,
          conversationText, setConversationText,
          templateTitle, setTemplateTitle,
          selectedNav,setSelectedNav, 
          message, setMessage,
          mergedContent, setMergedContent,
          currentUser}}>   
        <Outlet />
      </DataContext.Provider>

    </main>

    </div>
  );
};

export default HeaderLayout;
